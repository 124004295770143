.Footer {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    text-align: left;
    background-color: var(--grey-1);
    color: black;
    font-weight: 500;
    border-top: solid var(--accent) 2px;
}

.Footer-container {
    display: grid;
}

@media (min-width: 768px) {
    .Footer {
        padding: 50px;
    }

    .Footer-container {
        grid-template-columns: 50% 50%;
    }

    .Footer-right {
        text-align: right;
    }
}

@media (max-width: 768px) {
    .Footer {
        padding: 15px;
    }

    .Footer-container {
        grid-template-columns: 100%;
        row-gap: 20px;
    }

    .Footer-right {
        text-align: center;
    }
}

.Footer-link {
    color: var(--accent);
    border-radius: 5px;
}

.Footer-link:hover {
    color: var(--accent-variant);
    cursor: pointer;
    transition: 0.25s;
}

.Footer-svg {
    fill: var(--accent);
    color: var(--accent);
}

.Footer-svg:hover {
    cursor: pointer;
    fill: var(--accent-variant);
    transition: 0.25s;
}

.Footer-tag {
    margin-top: 40px;
}

.Footer-link-social {
    margin: 0;
    font-size: x-large;
    position: relative;
}

.Footer-link-social:hover {
    cursor: pointer;
    color: var(--accent-variant) !important;
    transition: 0.15s;
}

.Footer-email {
    word-wrap: break-word;
}