.Home {
  min-height: calc(100vh - 228px);
  text-align: center;
}

.Home-banner {
  display: grid;
  width: 100%;
  min-height: 80vh;
  background-size: cover;
  background-position-x: center;
  border-bottom: outset 3px var(--primary);
  background-blend-mode: color;
}

.Home-rodeo {
  background-size: cover;
  background-position: center;
}

.Home-title {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
  font-size: 50px;
}

.Home-video-player {
  width: 75%;
  aspect-ratio: calc(560 / 315);
  margin: 2rem 0 1rem 0;
}

@media (max-width: 300px) {
  .Home-video-player {
    width: 100%;
  }

  .Home-title {
    font-size: 40px;
  }
}

.Home-info {
  position: absolute;
  color: var(--primary);
  text-align: left;
  border: outset 2px var(--primary);
  border-radius: 8px;
  font-style: italic;
  padding: 15px 30px 5px 15px;
  background-color: white;
  height: fit-content;
}

.Home-title-comp {
  color: var(--text-primary);
}

.Home-equipment {
  display: grid;
  margin-top: 40px;
  /* transform: translate(0px, -75px); */
}

@media (min-width: 768px) {
  .Home-banner {
    grid-template-columns: 40% 60%;
  }

  .Home-carousel {
    height: 100%;
  }

  .Home-info {
    right: 0;
    margin-left: auto;
    margin-right: 15px;
    width: fit-content;
  }

  .Home-equipment {
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
  }

  .Home-banner-caption-text {
    font-size: 125px;
  }

  .Home-rodeo,
  .Home-services {
    grid-template-columns: 30% 70%;
  }

  .Home-get-started,
  .Home-testimonials {
    grid-template-columns: 70% 30%;
  }

  .Home-location {
    grid-template-columns: 50% 50%;
  }

  .Home-image {
    height: 100%;
  }

  .Home-image-overlay-left {
    background: linear-gradient(to right, #00000033 70%, white);
  }

  .Home-image-overlay-right {
    background: linear-gradient(to left, #00000033 70%, var(--grey-1));
  }

  .Home-image-overlay-location {
    background: linear-gradient(to left, #00000033 70%, white);
  }
}

@media (max-width: 768px) {
  .Home-video-player {
    width: 100%;
  }

  .Home-banner {
    grid-template-columns: 100%;
  }

  .Home-carousel {
    height: 90vh;
  }

  .Home-about,
  .Home-info {
    width: 95%;
  }

  .Home-info {
    bottom: 75px;
    margin-bottom: 15px;
  }

  .Home-equipment {
    grid-template-columns: 100%;
    row-gap: 20px;
  }

  .Home-banner-caption-text {
    font-size: 75px;
  }

  .Home-rodeo,
  .Home-get-started,
  .Home-services,
  .Home-testimonials,
  .Home-location {
    grid-template-columns: 100%;
  }

  .Home-image {
    height: 400px;
  }

  .Home-image-overlay-left {
    background: linear-gradient(to bottom, #00000033, white);
  }

  .Home-image-overlay-right {
    background: linear-gradient(to bottom, #00000033, var(--grey-1));
  }

  .Home-image-overlay-location {
    background: linear-gradient(to bottom, #00000033 70%, white);
  }
}

#home-logo {
  color: var(--text-accent);
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.Home-banner-image {
  position: absolute;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

.Home-banner-shade {
  z-index: 1;
  position: absolute;
  background-image: radial-gradient(
    #00000066 40%,
    #c0c0c000 70%,
    var(--grey-4)
  );
  height: 100%;
  width: 100%;
}

.Home-banner-caption {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 100vw;
  padding: 20px;
}

.Home-banner-caption-text {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
}

.Home-carousel {
  width: 100%;
}

.Home-carousel-item,
.carousel-inner {
  height: 100%;
  width: 100%;
}

.Home-about {
  display: flex;
  color: black;
  font-weight: 600;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 50px;
  width: 95%;
  align-items: center;
}

.Home-about-text {
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.Home-button {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  border-radius: 5px;
  border: solid black 1px;
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-top: 1rem;
}

.Home-button-about {
  background-color: var(--accent);
}

.Home-button-about:hover {
  background-color: var(--accent-variant);
  transition: 0.15s;
}

.Home-button-get-started {
  background-color: var(--accent);
}

.Home-button-get-started:hover {
  background-color: var(--accent-variant);
  transition: 0.15s;
}

.fa-angle-right {
  font-size: larger;
}

.Home-rodeo,
.Home-services,
.Home-location {
  display: grid;
  background-color: white;
}

.Home-get-started,
.Home-testimonials {
  display: grid;
  background-color: var(--grey-1);
}

.Home-image {
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Home-rodeo-text,
.Home-get-started-text,
.Home-services-text {
  display: flex;
  padding: 2rem;
  color: black;
  font-weight: 500;
  align-items: center;
}

.Home-rodeo,
.Home-get-started,
.Home-services,
.Home-testimonials,
.Home-rodeo {
  min-height: 400px;
}

.Home-image-overlay-left,
.Home-image-overlay-right,
.Home-image-overlay-location {
  height: 100%;
  width: 100%;
}

#back-to-top {
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-size: 35px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff44;
  color: #000000cc;
  z-index: 5;
  box-shadow: 2px 2px 2px #000000cc, -2px -2px 2px #000000cc,
    2px -2px 2px #000000cc, -2px 2px 2px #000000cc;
}

#back-to-top:hover {
  background-color: #ffffffaa;
  color: #000000;
  box-shadow: 2px 2px 2px #000000, -2px -2px 2px #000000, 2px -2px 2px #000000,
    -2px 2px 2px #000000;
  transition: 0.15s;
}

.Home-container {
  margin: auto;
}

.Home-location-text {
  font-family: "Montserrat", sans-serif;
  margin-top: 3rem;
}

.Home-image-rodeo {
  background-position-x: 75%;
}
