.Map {
  margin: 40px;
}

@media (min-width: 768px) {
  .Map {
    width: 75%;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .Map {
    width: 90%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10vw;
  }
}
