.Services {
  overflow-y: auto;
}

.Services-content {
  background-color: var(--grey-6);
  display: grid;
}

.Services-photo {
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}

.Services-text {
  padding: 2rem;
  color: white;
}

.Services-title {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
  font-size: 50px;
}

.Services-text-content {
  font-family: "Montserrat", sans-serif;
}

.Services-image-overlay-left {
  width: 100%;
  background: linear-gradient(to right, #00000033 40%, var(--grey-6));
}

.Services-list-photo-right,
.Services-list-photo-left {
  display: grid;
  min-height: 400px;
}

.Services-list-photo-left {
  grid-template-columns: 35% 65%;
  background-color: var(--grey-1);
}

.Services-list-photo-right {
  grid-template-columns: 65% 35%;
  background-color: white;
}

@media (min-width: 1000px) {
  .Services-photo {
    height: 100%;
  }

  .Services-content {
    grid-template-columns: 60% 40%;
  }

  .Services-image-overlay-left {
    background: linear-gradient(to right, #00000033 40%, var(--grey-6));
    height: 100%;
  }

  .Services-text-content {
    line-height: 1.75;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    padding: 4rem 0;
  }

  .Services-container {
    width: 85%;
  }
}

@media (max-width: 1000px) {
  .Services-photo {
    height: fit-content;
  }

  .Services-content {
    grid-template-columns: 100%;
  }

  .Services-image-overlay-left {
    background: linear-gradient(to bottom, #00000033 40%, var(--grey-6));
    height: 0;
    padding-bottom: 71.142857%;
  }

  .Services-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .Services-body-image {
    height: 100%;
  }

  .Services-body-image-overlay-left {
    background: linear-gradient(to right, #00000033 70%, var(--grey-1));
  }

  .Services-body-image-overlay-right {
    background: linear-gradient(to left, #00000033 70%, white);
  }
}

@media (max-width: 768px) {
  .Services-list-photo-right,
  .Services-list-photo-left {
    grid-template-columns: 100%;
  }

  .Services-body-image {
    height: 400px;
  }

  .Services-body-image-overlay-left {
    background: linear-gradient(to bottom, #00000033, var(--grey-1));
  }

  .Services-body-image-overlay-right {
    background: linear-gradient(to bottom, #00000033, white);
  }
}

.Services-body-image {
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Services-body-image-overlay-left,
.Services-body-image-overlay-right {
  height: 100%;
  width: 100%;
}

.Services-body-text {
  display: flex;
  padding: 2rem;
  color: black;
  font-weight: 500;
  align-items: center;
}

.Services-container {
  margin: auto;
}

.Services-body-title {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
  font-size: 50px;
}

@media (max-width: 400px) {
  .Services-body-title {
    font-size: 40px;
  }
}

@media (max-width: 300px) {
  .Services-body-title {
    font-size: 32px;
  }
}
