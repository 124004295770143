.StaffCard {
  display: grid;
  width: 100%;
  height: fit-content;
  background-color: var(--grey-6);
  text-align: left;
  border-radius: 3px;
  color: white;
}

.StaffCard-photo {
  border-radius: 3px;
  width: calc(100% - 2rem);
  aspect-ratio: 4 / 3;
  background-size: cover;
  background-position-x: center;
  background-position-y: 95%;
  margin: 2rem;
}

@media (min-width: 768px) {
  .StaffCard {
    grid-template-columns: 100%;
  }

  .StaffCard-photo {
    margin: 1rem;
  }
}

@media (max-width: 1000px) {
  .StaffCard-photo {
    margin: 1rem auto;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .StaffCard {
    grid-template-columns: 100%;
  }
}

.StaffCard-text {
  padding: 1rem;
}

.StaffCard-name {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
}

.StaffCard-link:hover {
  cursor: pointer;
  color: var(--accent);
  transition: 0.15s;
}

.StaffCard-title {
  font-family: "Montserrat", sans-serif;
}

.StaffCard-bio {
  font-family: "Montserrat", sans-serif;
  margin: 1rem 0;
}
