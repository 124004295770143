.About {
  overflow-y: auto;
}

.About-content {
  background-color: var(--grey-6);
  display: grid;
}

.About-photo {
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: 35%;
}

.About-text {
  padding: 0.5rem;
  color: white;
}

.About-title {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
  font-size: 50px;
}

.About-subtitle {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
}

.About-text-content {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

.About-image-overlay-left {
  width: 100%;
  background: linear-gradient(to right, #00000033 75%, var(--grey-6));
}

@media (min-width: 1000px) {
  .About-photo {
    height: 100%;
  }

  .About-content {
    grid-template-columns: 65% 35%;
  }

  .About-image-overlay-left {
    background: linear-gradient(to right, #00000033 75%, var(--grey-6));
    height: 100%;
  }
}

@media (max-width: 1000px) {
  .About-photo {
    height: fit-content;
  }

  .About-content {
    grid-template-columns: 100%;
  }

  .About-image-overlay-left {
    background: linear-gradient(to bottom, #00000033 80%, var(--grey-6));
    height: 0;
    padding-bottom: 60%;
  }
}

@media (max-width: 1000px) {
  .About-staff-grid {
    width: 90%;
  }
}

/* @media (min-width: 1000px) {
  .About-staff-grid {
    width: 70%;
  }
} */

.About-staff {
  background-color: white;
  color: black;
  padding: 2rem 0;
  margin: 0;
}

.About-staff-grid {
  width: 90%;
  margin: 2rem auto;
  display: grid;
  justify-content: center;
  grid-template-columns: 33.333% 33.333% 33.333%;
  row-gap: 4rem;
  column-gap: 2rem;
  grid-auto-flow: row;
}

.lubbock,
.glenRose {
  grid-template-columns: 33.333% 33.333%;
}

.About-ul {
  text-align: left;
}

.About-li {
  margin: 1rem 0;
}

.About-staff-group-photo {
  width: 100%;
  aspect-ratio: 20 / 7;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  margin: 3rem auto;
}

@media (max-width: 1000px) {
  .About-staff-grid {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 768px) {
  .About-staff-grid {
    grid-template-columns: 100%;
  }
}
